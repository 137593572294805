// 考题模块
import { request, noTimeOutReq } from '@/utils/request.js'

// 新增考题
export function questionsAdd (data) {
  return request({
    url: '/exam/operation/questions/add',
    method: 'post',
    data
  })
}

// 删除考题
export function questionsDelete (ids) {
  return request({
    url: `/exam/operation/questions/delete/${ids}`,
    method: 'post',
  })
}

// // 下载考题模板
// export function download (data) {
//   return request({
//     url: `/admin/excel/test/question/excel/download`,
//     method: 'post',
//     data
//   })
// }

// // 导入Excel
// export function importExcel (data) {
//   return noTimeOutReq({
//     url: `/admin/excel/test/question/excel`,
//     headers: { 'Content-Type': 'multipart/form-data' },
//     method: 'post',
//     data
//   })
// }

// // 根据学科查询考试安排所需考题信息
// export function getSubjectQuestions (subjectType) {
//   return request({
//     url: `/exam/operation/questions/get`,
//     method: 'post',
//     subjectType: subjectType
//   })
// }

// 根据考题id获取考题各评分项答案及分数
export function getAnswersById (data) {
  return request({
    url: `/exam/operation/questions/getAnswersById`,
    method: 'post',
    data: `examQuestionOperationTestId=${data}`
  })
}

// 根据考题id获取试卷pdf
export function getPdfById (data) {
  return request({
    url: `/exam/operation/questions/getPdfById`,
    method: 'post',
    data: `examQuestionOperationTestId=${data}`
  })
}

// 获取各学科考题总数
export function getSubjectTotal () {
  return request({
    url: `/exam/operation/questions/getSubjectTotal`,
    method: 'post',
  })
}

// 根据id获取当前考题详情
export function questionDetails (id) {
  return request({
    url: `/exam/operation/questions/selectById/${id}`,
    method: 'post',
  })
}

// 分页查询
export function selectByPages (data) {
  if (data.subjectType) {
    return request({
      url: `/exam/operation/questions/selectByPages`,
      method: 'post',
      data: `page=${data.page}&rows=${data.rows}&subjectType=${data.subjectType}`
    })
  } else {
    return request({
      url: `/exam/operation/questions/selectByPages`,
      method: 'post',
      data: `page=${data.page}&rows=${data.rows}`
    })
  }
}

// 更新考题
export function questionsUpdate (data) {
  return request({
    url: `/exam/operation/questions/update`,
    method: 'post',
    data
  })
}


// 上传pdf返图片
export function importPdf (data) {
  return noTimeOutReq({
    url: `/function/oss/upload/PdfToPng/file`,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}

// 上传单个文件
export function importOne (data) {
  return noTimeOutReq({
    url: `/function/oss/upload/single/file`,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}


// 上传单个文件
export function importJson (data) {
  return noTimeOutReq({
    url: `/exam/operation/questions/import/json`,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}

// 根据考题id导出考题数据json文件
export function derivedJson (examQuestionOperationTestId) {
  return noTimeOutReq({
    url: `/exam/operation/questions/derived/json/${examQuestionOperationTestId}`,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
  })
}